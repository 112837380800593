import { ModalHTMLElement } from './modal';

export function initYoutubeIframe() {
    document.querySelectorAll<YoutubeIframeHTMLElement>('.js-youtube').forEach($el => {
        const videoId = <string>$el.dataset.id;
        const playerVars = {
            rel: 0,
        }
        if (videoId) {
            $el.youtubeIframe = new YoutubeIframe($el, videoId, playerVars);
        }
    });

    document.addEventListener('modalopen', evt => {
        const $modal = (evt as CustomEvent).detail as ModalHTMLElement;
        const $modalContent = $modal.querySelector<HTMLElement>('.js-modal-content');
        const $youtubeIframeContainer = $modalContent?.querySelector<YoutubeIframeHTMLElement>('.js-youtube');
        if ($youtubeIframeContainer && $youtubeIframeContainer.youtubeIframe) {
            $youtubeIframeContainer.youtubeIframe.player.playVideo();
        }
    });

    document.addEventListener('modalclose', evt => {
        const $modal = (evt as CustomEvent).detail as ModalHTMLElement;
        const $modalContent = $modal.querySelector<HTMLElement>('.js-modal-content');
        const $youtubeIframeContainer = $modalContent?.querySelector<YoutubeIframeHTMLElement>('.js-youtube');
        if ($youtubeIframeContainer && $youtubeIframeContainer.youtubeIframe) {
            $youtubeIframeContainer.youtubeIframe.player.pauseVideo();
        }
    });
}

/* eslint-disable @typescript-eslint/no-explicit-any */
declare const YT: any;
/* eslint-enable @typescript-eslint/no-explicit-any */

declare global {
    interface Window {
        axeptioSDK?: {
            requestConsent(vendor: string): boolean;
        };
        _axcb?: Array<(axeptio) => void>;
    }
}

interface YoutubeIframeHTMLElement extends HTMLElement {
    youtubeIframe: YoutubeIframe | null;
}

class YoutubeIframe {
    player: typeof YT.Player;
    constructor($el: YoutubeIframeHTMLElement, videoId: string, playerVars: object) {
        const initPlayer = () => {
            YT.ready(() => {
                this.player = new YT.Player($el.querySelector('.player'), {
                    videoId,
                    playerVars
                });
            });
        };

        window._axcb = window._axcb || [];
        // Si axeptio n'est pas présent, on charge directement la vidéo
        if (window._axcb?.length === 0) {
            if (typeof window.axeptioSDK === 'undefined') {
                initPlayer();
                return;
            }
        }

        window._axcb.push(() => {
            // Si axeptio existe, on vérifie le consentement
            if (window.axeptioSDK?.requestConsent('Youtube')) {
                initPlayer();
            } else {
                const placeholder = document.createElement('div');
                placeholder.classList.add('youtube-consent-needed');
                placeholder.textContent = 'Veuillez accepter les cookies YouTube pour voir cette vidéo';
                $el.querySelector('.player')?.replaceWith(placeholder);
            }
        });
    }
}
